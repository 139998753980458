import { AiOutlineLoading3Quarters } from "react-icons/ai";
import LOGO from "@Assets/logo.png";

export default function PageLoading({ text, update }: any) {
  if (update) {
    return (
      <div className="w-screen h-screen absolute top-0 left-0 bg-transparent cursor-progress"></div>
    );
  }
  return (
    <div className="w-screen h-screen bg-background absolute top-0 left-0 grid place-items-center text-primary">
      <div className="flex flex-col gap-4 items-center justify-center text-center">
        <img src={LOGO} width={84} className=" animate-bounce" />
        <div className="flex gap-3 items-center">
          <AiOutlineLoading3Quarters className="animate-spin" />
          {text}
        </div>
      </div>
    </div>
  );
}
