import {
  User,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import React, { createContext, useContext, useEffect } from "react";
import { auth, db, googleProvider } from "@Config/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import PageLoading from "@Components/PageLoading";
import { setUser as setSentryUser } from "@sentry/react";
import { useTheme } from "@nextui-org/use-theme";

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }: any) {
  const [user, setUser] = React.useState<User | null>(null);
  const [userData, setUserData] = React.useState<UserData>({} as UserData);
  const [loading, setLoading] = React.useState(true);

  const location = useLocation();
  const navigate = useNavigate();
  const { setTheme } = useTheme();

  async function signup(email: string, password: string) {
    return await createUserWithEmailAndPassword(auth, email, password);
  }

  async function continueWithGoogle() {
    await signInWithPopup(auth, googleProvider);
    if (!getUserData()) navigate("/onboarding");
  }

  async function login(email: string, password: string) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  async function getUserData() {
    const userDataDoc = await getDoc(doc(db, `users/${user?.uid}`));
    if (userDataDoc.exists()) setUserData(userDataDoc.data() as UserData);
    return userDataDoc.exists();
  }

  function logout() {
    if (sessionStorage.getItem("organization_id"))
      sessionStorage.removeItem("organization_id");
    if (sessionStorage.getItem("brand_id"))
      sessionStorage.removeItem("brand_id");
    if (sessionStorage.getItem("warehouse_id"))
      sessionStorage.removeItem("warehouse_id");
    return signOut(auth);
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser: any) => {
      setUser(currentUser);
      setSentryUser(currentUser ? { email: currentUser.email } : null);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    const nonProtectedPaths = ["/login", "/signup", "/"];
    if (!loading) {
      (async () => {
        if (user === null) {
          setTheme("light");
          if (!nonProtectedPaths.includes(location.pathname))
            navigate("/login");
        }

        if (user?.email) {
          if (await getUserData()) {
            if (nonProtectedPaths.includes(location.pathname)) {
              navigate("/dashboard");
            } else {
              navigate(location.pathname);
            }
          } else {
            navigate("/onboarding");
          }
        }
      })();
    }
  }, [user, loading]);

  const value: AuthContextProps = {
    user,
    logout,
    login,
    signup,
    continueWithGoogle,
    userData,
    getUserData,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading ? children : <PageLoading text={"Logging In..."} />}
    </AuthContext.Provider>
  );
}
