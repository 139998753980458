import { AiOutlineLoading } from "react-icons/ai";
import React, { createContext, useContext, useEffect } from "react";
import { db } from "@Config/firebase";
import {
  and,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { useAuth } from "./AuthContext";
import PageLoading from "@Components/PageLoading";
import DataEngine from "@Utility/DataEngine";
// import { getIdToken } from "firebase/auth";

const DataContext = createContext<DataContextProps>({} as DataContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export function useData() {
  return useContext(DataContext);
}
export function DataProvider({ children }: any) {
  const [organizations, setOrganizations] = React.useState<Organization[]>(
    [] as Organization[]
  );
  const [suppliers, setSuppliers] = React.useState<{
    [id: string]: Supplier[];
  }>({} as { [id: string]: Supplier[] });
  const [catalogs, setCatalogs] = React.useState<{
    [id: string]: Catalog[];
  }>({} as { [id: string]: Catalog[] });
  const [products, setProducts] = React.useState<{
    [id: string]: Product[];
  }>({} as { [id: string]: Product[] });
  const [brands, setBrands] = React.useState<{ [id: string]: Brand[] }>(
    {} as { [id: string]: Brand[] }
  );
  const [locations, setLocations] = React.useState<{
    [id: string]: BrandLocation[];
  }>({} as { [id: string]: BrandLocation[] });
  const [allLocations, setAllLocations] = React.useState<{
    [id: string]: BrandLocation[];
  }>({} as { [id: string]: BrandLocation[] });
  const [warehouses, setWarehouses] = React.useState<{
    [id: string]: Warehouse[];
  }>({} as { [id: string]: Warehouse[] });
  const [locationInventory, setLocationInventory] = React.useState<{
    [id: string]: LocationInventoryItem[];
  }>({} as { [id: string]: LocationInventoryItem[] });
  const [forecasts, setForecasts] = React.useState<{
    [id: string]: Forecast[];
  }>({} as { [id: string]: Forecast[] });
  const [factories, setFactories] = React.useState<{
    [id: string]: Factory[];
  }>({} as { [id: string]: Factory[] });
  const [lines, setLines] = React.useState<{
    [id: string]: ProductionLine[];
  }>({} as { [id: string]: ProductionLine[] });
  const [workstations, setWorkstations] = React.useState<{
    [id: string]: Workstation[];
  }>({} as { [id: string]: Workstation[] });
  const [tasks, setTasks] = React.useState<{
    [id: string]: WorkTask[];
  }>({} as { [id: string]: WorkTask[] });
  const [warehouseInventory, setWarehouseInventory] = React.useState<{
    [id: string]: WarehouseInventoryItem[];
  }>({} as { [id: string]: WarehouseInventoryItem[] });
  const [warehouseSchedule, setWarehouseSchedule] = React.useState<{
    [id: string]: WarehouseEvent[];
  }>({} as { [id: string]: WarehouseEvent[] });
  const [external, setExternal] = React.useState<ExternalData | null>(null);
  const [warehouseOrders, setWarehouseOrders] = React.useState<{
    [id: string]: WarehouseOrder[];
  }>({} as { [id: string]: WarehouseOrder[] });
  const [locationOrders, setLocationOrders] = React.useState<{
    [id: string]: LocationOrder[];
  }>({} as { [id: string]: LocationOrder[] });
  const [organization, setOrganization] = React.useState<Organization>(
    {} as Organization
  );

  const [loadingText, setLoadingText] = React.useState("Updating Data...");

  const [organizationId, setOrganizationId] = React.useState<string>(
    sessionStorage.getItem("organization_id") || organizations[0]?.id || ""
  );

  // @ts-expect-error
  const [refreshVal, setRefreshVal] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [externalLoading, setExternalLoading] = React.useState(false);
  // const [salesLoading, setSalesLoading] = React.useState(false);

  const { user, getUserData } = useAuth();

  const getOrganizations = async () => {
    setLoadingText("Getting Organizations...");
    const orgs = (await getDocs(collection(db, "organizations"))).docs.map(
      (orgDoc) => ({
        ...orgDoc.data(),
        id: orgDoc.id,
      })
    );

    let memberOrgs = orgs.filter((org: any) => {
      const isMember = org.members.filter((orgMember: any) => {
        if (orgMember.uid === user.uid) {
          return true;
        }
      });
      return Boolean(isMember.length);
    });
    setOrganizations(memberOrgs as unknown as Organization[]);

    return memberOrgs as unknown as Organization[];
  };

  const getWarehouseOrders = async (data: any) => {
    let allOrders: any = {};
    setLoadingText("Getting Warehouse Orders...");

    const orgsArray = data.orgs.map(async (organization: Organization) => {
      data.wares[String(organization.id)].map(async (warehouse: Warehouse) => {
        const orders = (
          await getDocs(
            collection(
              db,
              `organizations/${organization.id}/warehouses/${warehouse.id}/orders`
            )
          )
        ).docs.map((orderDoc) => ({
          id: orderDoc.id,
          ...orderDoc.data(),
        }));
        allOrders[String(warehouse.id)] = orders;
        return orders;
      });
    });

    await Promise.all(orgsArray);

    setWarehouseOrders(allOrders);

    return allOrders;
  };

  const getLocationOrders = async (data: any) => {
    let allLocationOrders: any = {};
    setLoadingText("Getting Location Orders...");

    const orgsArray = data.orgs.map(async (organization: Organization) => {
      data.brnds[String(organization.id)].map(async (brand: Brand) => {
        data.locs[String(brand.id)].map(async (location: BrandLocation) => {
          const locOrders = (
            await getDocs(
              collection(
                db,
                `organizations/${organization.id}/brands/${brand.id}/locations/${location.id}/orders`
              )
            )
          ).docs.map((orderDoc) => ({
            id: orderDoc.id,
            ...orderDoc.data(),
          }));

          allLocationOrders[String(location.id)] = locOrders;
          return locOrders;
        });
      });
    });

    await Promise.all(orgsArray);

    setLocationOrders(allLocationOrders);

    return allLocationOrders;
  };

  const getOrganization = (id: string) => {
    return organizations.filter((org) => org.id === id)?.[0];
  };

  const updateOrganization = async (id: string, data: object) => {
    return await updateDoc(doc(db, `organizations/${id}`), data);
  };

  const getBrands = async ({ orgs }: any) => {
    const brnds = {};

    setLoadingText("Getting Brands...");

    const brndLoopArray = orgs.map(async (org: Organization) => {
      const brndArray = (
        await getDocs(collection(db, `organizations/${org.id}/brands`))
      ).docs.map((orgDoc) => ({
        ...orgDoc.data(),
        id: orgDoc.id,
      })) as unknown as Brand[];
      // @ts-expect-error
      brnds[org.id] = brndArray;
      return brndArray;
    });

    await Promise.all(brndLoopArray);
    setBrands(brnds as { [id: string]: Brand[] });
    return brnds as { [id: string]: Brand[] };
  };

  const getSuppliers = async (
    orgs: any
  ): Promise<{
    [id: string]: Supplier[];
  }> => {
    setLoadingText("Getting Suppliers...");

    const suppliers: any = {};
    const array = orgs.map(async (org: any) => {
      const catalogRef = await getDocs(
        collection(db, `organizations/${org.id}/suppliers`)
      );
      suppliers[org.id] = catalogRef.docs.map((supplierDoc) => ({
        ...supplierDoc.data(),
        id: supplierDoc.id,
      })) as Supplier[];
      return catalogRef;
    });
    await Promise.all(array);
    setSuppliers(suppliers);
    return suppliers as {
      [id: string]: Supplier[];
    };
  };

  const getCatalogs = async (orgs: any) => {
    setLoadingText("Getting Catalogs...");
    const catalogs: any = {};
    const array = orgs.map(async (org: any) => {
      const catalogRef = await getDocs(
        collection(db, `organizations/${org.id}/catalogs`)
      );
      catalogs[org.id] = catalogRef.docs.map((catalogDoc) => ({
        ...catalogDoc.data(),
        id: catalogDoc.id,
      })) as Catalog[];
      return catalogRef;
    });
    await Promise.all(array);
    setCatalogs(catalogs);
  };

  const getProducts = async (orgs: any) => {
    setLoadingText("Getting Products...");

    const products: any = {};
    const array = orgs.map(async (organization: Organization) => {
      const productsRef = await getDocs(
        collection(db, `organizations/${organization.id}/products`)
      );
      const array = productsRef.docs.map((orgDoc) => ({
        ...orgDoc.data(),
        id: orgDoc.id,
      })) as unknown as Product[];

      products[String(organization.id)] = array;

      return array;
    });

    await Promise.all(array);
    setProducts(products as { [id: string]: Product[] });
  };

  const getLocations = async (data: any) => {
    setLoadingText("Getting Locations...");

    const { brnds, orgs } = data;
    const locs: any = {};
    const orgArray = orgs.map(async (organization: Organization) => {
      const brndsArray = brnds[String(organization.id)].map(
        async (brand: Brand) => {
          const loc = (
            await getDocs(
              collection(
                db,
                `organizations/${organization.id}/brands/${brand.id}/locations`
              )
            )
          ).docs.map((locDoc) => ({ ...locDoc.data(), id: locDoc.id }));

          locs[String(brand.id)] = loc;
          return loc;
        }
      );
      return await Promise.all(brndsArray);
    });
    await Promise.all(orgArray);
    setLocations(locs as { [id: string]: BrandLocation[] });
    return locs;
  };

  const getWarehouses = async (orgs = organizations) => {
    setLoadingText("Getting Warehouses...");

    const ware = {};

    const brndLoopArray = orgs.map(async (org) => {
      const brndArray = (
        await getDocs(collection(db, `organizations/${org.id}/warehouses`))
      ).docs.map((orgDoc) => ({
        ...orgDoc.data(),
        id: orgDoc.id,
      })) as unknown as Brand[];
      // @ts-expect-error
      ware[org.id] = brndArray;
      return brndArray;
    });

    await Promise.all(brndLoopArray);
    setWarehouses(ware as { [id: string]: Warehouse[] });
    return ware;
  };

  const getLocationInventory = async (data: any) => {
    setLoadingText("Getting Location Inventories...");

    const orgs = data.orgs;
    const brnds = data.brnds;
    const locs = data.locs;

    let allInventory: any = {};

    const orgsArray = orgs.map(async (organization: Organization) => {
      const brndsArray = brnds[String(organization.id)].map(
        async (brand: Brand) => {
          const locsArray = locs[String(brand.id)].map(
            async (location: BrandLocation) => {
              allInventory[String(location.id)] = (
                await getDocs(
                  collection(
                    db,
                    `organizations/${organization.id}/brands/${brand.id}/locations/${location.id}/inventory`
                  )
                )
              ).docs.map((inventoryDoc) => ({
                id: inventoryDoc.id,
                ...inventoryDoc.data(),
              }));
            }
          );
          return await Promise.all(locsArray);
        }
      );
      return await Promise.all(brndsArray);
    });

    await Promise.all(orgsArray);

    setLocationInventory(allInventory);
    return allInventory;
  };

  const getWarehouseInventory = async (data: any) => {
    const orgs = data.orgs;
    const wares = data.wares;
    setLoadingText("Getting Warehouse Inventories...");

    let allInventory: any = {};

    const orgsArray = orgs.map(async (organization: Organization) => {
      const brndsArray = wares[String(organization.id)].map(
        async (ware: Warehouse) => {
          const wareinventory = (
            await getDocs(
              collection(
                db,
                `organizations/${organization.id}/warehouses/${ware.id}/inventory`
              )
            )
          ).docs.map((inventoryDoc) => ({
            id: inventoryDoc.id,
            ...inventoryDoc.data(),
          }));
          allInventory[String(ware.id)] = wareinventory;
          return wareinventory;
        }
      );
      return await Promise.all(brndsArray);
    });

    await Promise.all(orgsArray);

    setWarehouseInventory(allInventory);
    return allInventory;
  };

  const getWarehouseSchedule = async (data: any) => {
    const orgs = data.orgs;
    const wares = data.wares;

    let allInventory: any = {};
    setLoadingText("Getting Warehouse Schedule...");

    const orgsArray = orgs.map(async (organization: Organization) => {
      const brndsArray = wares[String(organization.id)].map(
        async (ware: Warehouse) => {
          const wareinventory = (
            await getDocs(
              collection(
                db,
                `organizations/${organization.id}/warehouses/${ware.id}/schedule`
              )
            )
          ).docs.map((inventoryDoc) => ({
            id: inventoryDoc.id,
            ...inventoryDoc.data(),
          }));
          allInventory[String(ware.id)] = wareinventory;
          return wareinventory;
        }
      );
      return await Promise.all(brndsArray);
    });

    await Promise.all(orgsArray);

    setWarehouseSchedule(allInventory);
    return allInventory;
  };

  const getSales = async (data: any) => {
    const orgs = data.orgs;
    const brnds = data.brnds;
    const locs = data.locs;

    setLoadingText("Getting Forecasts...");

    let demand: any = {};

    const orgsArray = orgs.map(async (organization: Organization) => {
      const brndsArray = brnds[String(organization.id)].map(
        async (brand: Brand) => {
          const locsArray = locs[String(brand.id)].map(
            async (location: BrandLocation) => {
              if (organization.settings?.demand_type === "forecasts") {
                demand[String(location.id)] = (
                  await getDocs(
                    query(
                      collection(
                        db,
                        `organizations/${organization.id}/brands/${brand.id}/locations/${location.id}/forecast`
                      ),
                      and(
                        where("date", ">=", new Date()),
                        where(
                          "date",
                          "<=",
                          new Date(
                            new Date().setDate(new Date().getDate() + 10)
                          )
                        )
                      )
                    )
                  )
                ).docs.map((inventoryDoc) => ({
                  ...inventoryDoc.data(),
                  id: inventoryDoc.id,
                  date: inventoryDoc.data().date.toDate(),
                }));
              } else {
                demand[String(location.id)] = [];
              }
            }
          );
          return await Promise.all(locsArray);
        }
      );
      return await Promise.all(brndsArray);
    });

    await Promise.all(orgsArray);
    setForecasts(demand);
    demand = null;
    return demand;
  };

  // const getRemainingSales = async (data: any) => {
  //   const orgs = data.orgs;
  //   const brnds = data.brnds;
  //   const locs = data.locs;
  //   setLoadingText("Getting Sales...");
  //   setSalesLoading(true);

  //   const Module = await import("../wasm/pkg/wasm.js");
  //   await Module.default();

  //   let demand: any = {};

  //   const token = await getIdToken(user);

  //   const orgsArray = orgs.map(async (organization: Organization) => {
  //     const brndsArray = brnds[String(organization.id)].map(
  //       async (brand: Brand) => {
  //         const locsArray = locs[String(brand.id)].map(
  //           async (location: BrandLocation) => {
  //             demand[String(location.id)] = (
  //               await Module.get_sales(
  //                 token,
  //                 `organizations/${organization.id}/brands/${brand.id}/locations/${location.id}`
  //               )
  //             ).map((s: any) => ({
  //               ...s,
  //               date: new Date(s.date),
  //               demand: Number(s.demand),
  //             }));
  //           }
  //         );
  //         return await Promise.all(locsArray);
  //       }
  //     );
  //     return await Promise.all(brndsArray);
  //   });

  //   await Promise.all(orgsArray);
  //   setForecasts(demand);
  //   setSalesLoading(false);
  //   demand = {};
  //   return demand;
  // };

  const getFactories = async (data: any) => {
    let allFactories: any = {};

    setLoadingText("Getting Factories...");

    const orgsArray = data.orgs.map(async (organization: Organization) => {
      const fact = (
        await getDocs(
          collection(db, `organizations/${organization.id}/factories`)
        )
      ).docs.map((inventoryDoc) => ({
        id: inventoryDoc.id,
        ...inventoryDoc.data(),
      }));
      allFactories[String(organization.id)] = fact;
      return fact;
    });

    await Promise.all(orgsArray);

    setFactories(allFactories);
    return allFactories;
  };

  const getProductionLines = async (data: any) => {
    let allLines: any = [];

    setLoadingText("Getting Production Lines...");

    const orgLoop = data.orgs.map(async (org: Organization) => {
      const factLoop = data.facts[String(org.id)].map(async (fact: Factory) => {
        const line = (
          await getDocs(
            collection(db, `organizations/${org.id}/factories/${fact.id}/lines`)
          )
        ).docs.map((lineDoc) => ({ ...lineDoc.data(), id: lineDoc.id }));
        allLines[String(fact.id)] = line;
        return line;
      });
      await Promise.all(factLoop);
    });
    await Promise.all(orgLoop);
    setLines(allLines as { [id: string]: ProductionLine[] });
    return allLines;
  };

  const getWorkstations = async (data: any) => {
    let allWorkstation: any = [];
    setLoadingText("Getting Workstations...");

    const orgLoop = data.orgs.map(async (org: Organization) => {
      const factLoop = data.facts[String(org.id)].map(async (fact: Factory) => {
        const lineLoop = data.lines[String(fact.id)].map(
          async (line: ProductionLine) => {
            const workstat = (
              await getDocs(
                collection(
                  db,
                  `organizations/${org.id}/factories/${fact.id}/lines/${line.id}/workstations`
                )
              )
            ).docs.map((workDoc) => ({
              id: workDoc.id,
              ...workDoc.data(),
            }));
            allWorkstation[String(line.id)] = workstat;
            return workstat;
          }
        );
        await Promise.all(lineLoop);
      });
      await Promise.all(factLoop);
    });
    await Promise.all(orgLoop);
    setWorkstations(allWorkstation as { [id: string]: Workstation[] });
    return allWorkstation;
  };

  const getTasks = async (data: any) => {
    setLoadingText("Getting Tasks...");

    let allTasks: any = [];
    const orgLoop = data.orgs.map(async (org: Organization) => {
      const factLoop = data.facts[String(org.id)].map(async (fact: Factory) => {
        const lineLoop = data.lines[String(fact.id)].map(
          async (line: ProductionLine) => {
            const workStatLoop = data.workstats[String(line.id)].map(
              async (workstation: Workstation) => {
                const workstat = (
                  await getDocs(
                    collection(
                      db,
                      `organizations/${org.id}/factories/${fact.id}/lines/${line.id}/workstations/${workstation.id}/tasks`
                    )
                  )
                ).docs.map((workDoc) => ({
                  id: workDoc.id,
                  ...workDoc.data(),
                }));
                allTasks[String(workstation.id)] = workstat;
                return workstat;
              }
            );
            await Promise.all(workStatLoop);
          }
        );
        await Promise.all(lineLoop);
      });
      await Promise.all(factLoop);
    });
    await Promise.all(orgLoop);

    setTasks(allTasks as { [id: string]: WorkTask[] });
    return allTasks;
  };

  const fetchAllData = () => {
    if (user?.email) {
      (async () => {
        setLoading(true);
        const orgs = await getOrganizations();
        const wares = await getWarehouses(orgs);
        const facts = await getFactories({ orgs });
        const lines = await getProductionLines({ orgs, facts });
        const workstats = await getWorkstations({ orgs, facts, lines });
        const brnds = await getBrands({ orgs });
        const locs = await getLocations({ orgs, brnds });
        await getSuppliers(orgs);
        await getTasks({ orgs, facts, lines, workstats });
        await getLocationInventory({ orgs, brnds, locs });
        await getWarehouseInventory({ orgs, wares });
        await getWarehouseSchedule({ orgs, wares });
        await getSales({ orgs, brnds, locs });
        await getProducts(orgs);
        await getCatalogs(orgs);
        await getWarehouseOrders({ orgs, wares });
        await getLocationOrders({ orgs, brnds, locs });
        setLoading(false);
        // getRemainingSales({ orgs, brnds, locs });
      })();
    } else setLoading(false);
  };

  useEffect(() => {
    fetchAllData();
  }, [user]);

  useEffect(() => {
    if (user) {
      const _organization = getOrganization(organizationId);
      if (_organization?.["allow-connections"]) {
        (async () => {
          console.log("!!!!==== EXTERNAL DATA FETCH STARTED ====!!!!");
          setExternalLoading(true);
          const external = new DataEngine(
            organizationId,
            _organization["external-data"]?.sources as Source[]
          );
          await external.init().catch(console.error);
          !external.data &&
            console.warn("!!!!==== NO EXTERNAL DATA FETCHED ====!!!!");
          setExternal(external.data);
          setExternalLoading(false);
        })();
      }
    }
  }, [organizationId, loading, user]);

  useEffect(() => {
    if (user && organizationId) {
      if (organizations) setOrganization(getOrganization(organizationId));
      sessionStorage.setItem("organization_id", organizationId);
      sessionStorage.removeItem("brand_id");
    }
  }, [organizationId, user, organizations]);

  useEffect(() => {
    if (user) {
      if (
        organizations?.[0]?.id &&
        !organizations.find(
          (o) => o.id === String(sessionStorage.getItem("organization_id"))
        )
      ) {
        sessionStorage.setItem("organization_id", organizations[0].id);
        sessionStorage.removeItem("brand_id");
        setOrganizationId(organizations[0].id);
      }
    }
  }, [organizations, user]);

  useEffect(() => {
    if (organizations?.[0]?.id && user)
      setOrganizationId(
        sessionStorage.getItem("organization_id") || organizations[0]?.id || ""
      );
  }, [organizations, loading]);

  useEffect(() => {
    if (locations) {
      organizations.map((org) => {
        brands[String(org.id)].map((brand) => {
          setAllLocations({
            ...allLocations,
            [org.id as string]: locations[String(brand.id)],
          });
        });
      });
    }
  }, [locations]);

  /**
   *
   * Used to refetch data from the database
   *
   * @param {string} [update]
   */
  const refresh = async (
    update?:
      | "user"
      | "organizations"
      | "products"
      | "catalogs"
      | "suppliers"
      | "locations"
      | "warehouses"
      | "factories"
      | "lines"
      | "workstations"
      | "tasks"
  ) => {
    switch (update) {
      case "user":
        await getUserData();
        break;
      case "organizations": // This will refetch all the data without showing the loading screen
        await getOrganizations();
        break;
      case "products":
        await getProducts(organizations);
        break;
      case "catalogs":
        await getCatalogs(organizations);
        break;
      case "suppliers":
        await getSuppliers(organizations);
        break;
      case "locations":
        const locs = await getLocations({ orgs: organizations, brnds: brands });
        await getLocationInventory({
          orgs: organizations,
          brnds: brands,
          locs,
        });
        await getSales({ orgs: organizations, brnds: brands, locs });
        break;
      case "warehouses":
        const wares = await getWarehouses(organizations);
        await getWarehouseInventory({ orgs: organizations, wares });
        await getWarehouseSchedule({ orgs: organizations, wares });
        break;
      case "factories":
        const getFactory = await getFactories({ orgs: organizations });
        const getLine = await getProductionLines({
          orgs: organizations,
          facts: getFactory,
        });
        const getWorkstation = await getWorkstations({
          orgs: organizations,
          facts: getFactory,
          lines: getLine,
        });
        await getTasks({
          orgs: organizations,
          facts: getFactory,
          lines: getLine,
          workstats: getWorkstation,
        });
        break;
      case "lines":
        const lines = await getProductionLines({
          orgs: organizations,
          facts: factories,
        });
        const workstats = await getWorkstations({
          orgs: organizations,
          facts: factories,
          lines,
        });
        await getTasks({
          orgs: organizations,
          facts: factories,
          lines,
          workstats,
        });
        break;
      case "workstations":
        const workLines = await getProductionLines({
          orgs: organizations,
          facts: factories,
        });
        const workstation = await getWorkstations({
          orgs: organizations,
          facts: factories,
          lines: workLines,
        });
        await getTasks({
          orgs: organizations,
          facts: factories,
          lines: workLines,
          workstats: workstation,
        });
        break;
      case "tasks":
        const taskLines = await getProductionLines({
          orgs: organizations,
          facts: factories,
        });
        const taskWorkstations = await getWorkstations({
          orgs: organizations,
          facts: factories,
          lines: taskLines,
        });
        await getTasks({
          orgs: organizations,
          facts: factories,
          lines: taskLines,
          workstats: taskWorkstations,
        });
        break;
      default: {
        fetchAllData();
        setRefreshVal(Math.random());
      }
    }
    setRefreshVal(Math.random());
  };

  /**
   *
   * Checks if the action is permissible by the current user
   *
   * @param {("owner" | "editor" | "viewer")} action This is supposed to be the required "User Type" for the action, for example for deleting the user type must be admin.
   * @returns {*} if the action is permissible.
   */
  const permit = (action: "owner" | "editor" | "viewer") => {
    const organizationId = sessionStorage.getItem("organization_id") || "";
    const org = getOrganization(organizationId);

    if (org) {
      const member: any = org?.members?.find(
        (member) => member.uid === user.uid
      );
      if (!member) return false;

      const accessLevel = member.accessLevel;

      if (action === "owner" && accessLevel === 0) return true;
      if (action === "editor" && (accessLevel === 0 || accessLevel === 1))
        return true;
      if (
        action === "viewer" &&
        (accessLevel === 0 || accessLevel === 1 || accessLevel === 2)
      )
        return true;

      return false;
    }
  };

  const value: DataContextProps = {
    organizations,
    getOrganization,
    refresh,
    brands,
    updateOrganization,
    getSuppliers,
    permit,
    suppliers,
    products,
    catalogs,
    locations,
    warehouses,
    locationInventory,
    factories,
    lines,
    workstations,
    tasks,
    forecasts,
    warehouseInventory,
    warehouseSchedule,
    external,
    warehouseOrders,
    locationOrders,
    organizationId,
    setOrganizationId,
    organization,
    allLocations,
  };

  return (
    <DataContext.Provider value={value}>
      {!loading ? children : <PageLoading text={loadingText} />}
      <div className="absolute bottom-5 right-5 flex flex-col gap-1">
        {externalLoading && (
          <div className="bg-[#5E17EB] p-3 rounded-xl flex gap-3 items-center backdrop-blur-xl">
            <AiOutlineLoading className="animate-spin" /> Fetching External
            Data...
          </div>
        )}
        {/* {salesLoading && (
          <div className="bg-[#5E17EB] p-3 rounded-xl flex gap-3 items-center backdrop-blur-xl">
            <AiOutlineLoading className="animate-spin" /> Fetching Remaining
            Demand Data...
          </div>
        )} */}
      </div>
    </DataContext.Provider>
  );
}
