import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@nextui-org/react";
import { createContext, useContext, useState } from "react";

const PopupContext = createContext<PopupContextProps>({} as PopupContextProps);

// eslint-disable-next-line react-refresh/only-export-components
export function usePopup() {
  return useContext(PopupContext);
}

export function PopupProvider({ children }: any) {
  const modalDisclosure = useDisclosure();
  const confirmDisclosure = useDisclosure();

  const [text, setText] = useState("");
  const [onConfirmFunc, setOnConfirm] = useState<() => void>();
  const [loading, setLoading] = useState(false);

  const popup = ({ type, msg, onConfirm }: any) => {
    if (!type) {
      modalDisclosure.onOpen();
      setText(msg);
    }
    if (type === "confirm") {
      setOnConfirm(() => onConfirm);
      confirmDisclosure.onOpen();
      setText(msg);
    }
  };

  const value: PopupContextProps = {
    popup,
  };

  return (
    <PopupContext.Provider value={value}>
      <Modal style={{ color: "black" }} {...modalDisclosure}>
        <ModalContent>
          <ModalHeader>
            <>Alert</>
          </ModalHeader>
          <ModalBody>{text}</ModalBody>
          <ModalFooter>
            <Button onPress={() => modalDisclosure.onClose()}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {confirmDisclosure.isOpen && (
        <Modal style={{ color: "black" }} {...confirmDisclosure}>
          <ModalHeader>Confirm</ModalHeader>
          <ModalBody>{text}</ModalBody>
          <ModalFooter>
            <Button onPress={() => confirmDisclosure.onClose()}>Close</Button>
            <Button
              isDisabled={loading}
              onPress={async () => {
                setLoading(true);
                await onConfirmFunc?.();
                setLoading(false);
                confirmDisclosure.onClose();
              }}
            >
              Confirm
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {children}
    </PopupContext.Provider>
  );
}
